<template>
  <VRow>
    <VCol cols="12">
      <VRow>
        <VSpacer />
        <VCol cols="auto">
          <TTBtn
            :loading="licenseTable.loading"
            @click="issueLicenseDialog=true"
          >
            <VIcon left>
              fal fa-plus
            </VIcon>

            Выдать лицензию
          </TTBtn>
        </VCol>
      </VRow>
    </VCol>
    <VCol cols="12">
      <VRow align="center">
        <VCol cols="4">
          <LicenseSelect
            v-model="appFilter"
            :licenses-app="licensesApp"
            :disabled="licenseTable.loading"
          />
        </VCol>
        <VCol cols="4">
          <LicenseDaysFilter
            v-model="daysFilter"
            :disabled="licenseTable.loading"
            :day-limit="dayLimit"
          />
        </VCol>
        <VCol cols="4">
          <TTSelect
            v-model="typeFilter"
            label="Тип"
            :items="licensesType"
            :disabled="licenseTable.loading"
          />
        </VCol>
      </VRow>
    </VCol>
    <VCol cols="12">
      <CompanyLicenseTable
        :items="licenseTableItemsFiltered"
        :loading="licenseTable.loading"
        :headers="licenseTable.headers"
        @update:items="licenseTableUpdateItems"
        @deactivate:licences="confirmDeactivateLicences"
      />
    </VCol>
    <VDialog
      v-if="issueLicenseDialog"
      v-model="issueLicenseDialog"
      hide-overlay
      fullscreen
    >
      <IssueLicenseForm
        :licenses-app="indexLicense"
        :licenses="licenses"
        @cancel="issueLicenseDialog=false"
        @submit="createLicense"
      />
    </VDialog>

    <DeactivateLicenceDialog
      v-if="deactivateLicencesDTO.length > 0"
      v-model="showDeactivateLicencesDialog"
      :licences="deactivateLicencesDTO"
      @deactivate:licences="deactivateLicencesDialogDeactivate"
      @cancel="deactivateLicencesDialogCancel"
    />
  </VRow>
</template>

<script>
import IssueLicenseForm from '../../../components/v2/IssueLicenseForm.vue';
import LicenseSelect from '../../../components/v2/ui/LicenseSelect.vue';
import LicenseDaysFilter from '../../../components/v2/ui/LisenseFilter/LicenseDaysFilter.vue';
import DeactivateLicenceDialog from '../../../components/dialogs/DeactivateLicenceDialog.vue';
import CompanyLicenseTable from '../../../components/v2/CompanyLicenseTable.vue';

export default {
  name: 'CompanyLicense',
  components: {
    LicenseDaysFilter,
    LicenseSelect,
    DeactivateLicenceDialog,
    CompanyLicenseTable,
    IssueLicenseForm,
  },
  inject: ['Names'],
  props: {
    accountId: {
      required: true,
      type: String,
    },
    companyId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showDeactivateLicencesDialog: false,
      deactivateLicencesDTO: [],
      licenseTable: {
        items: [],
        loading: true,
      },
      issueLicenseDialog: false,
      indexLicense: [],
      licenses: [],
      appFilter: [],
      daysFilter: [],
      typeFilter: 'all',
    };
  },
  computed: {
    licenseTableItemsFiltered() {
      let { items } = this.licenseTable;
      if (this.typeFilter !== 'all') {
        items = items.filter((l) => l.type === this.typeFilter);
      }
      if (this.appFilter.length) {
        items = items.filter((l) => this.appFilter.includes(l.product.appName));
      }
      if (this.daysFilter.length) {
        if (this.daysFilter[0].text === 'range') {
          const { min, max } = this.daysFilter[0];
          items = items.filter((l) => l.duration.days >= min && l.duration.days <= max);
        } else {
          const days = this.daysFilter.map((d) => d.text);
          items = items.filter((l) => days.includes(l.duration.days));
        }
      }
      return items;
    },
    dayLimit() {
      const days = this.licenses.map((l) => l.duration.days);
      return {
        min: Math.min.apply(null, days) || 0,
        max: Math.max.apply(null, days) || 0,
      };
    },
    licensesApp() {
      return this.licenses.map((l) => ({
        name: l.appName,
        bundleName: l.bundleName,
      }));
    },
    licensesType() {
      return Object.entries(this.$t('licenses.type')).map(([value, text]) => ({ value, text }));
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.licenseTable.loading = true;
        const licenseApp = new this.$di.ddd.License();
        const { accountId, companyId } = this;
        [this.licenses] = await licenseApp.services.license.getByCompanyOrAccount({
          accountId,
          companyId,
        });

        this.licenseTable.items = this.licenses.map((license) => ({
          product: {
            id: license.id,
            appName: license.appName,
            bundleName: license.bundleName,
            checked: false,
          },
          users: {
            total: license.totalUsers,
            left: license.totalUsers - license.involvedUsers,
          },
          duration: {
            days: license.duration.days,
            left: this.$dayjs(license.duration.startsAt)
              .add(license.duration.days, 'day')
              .diff(Date.now(), 'day'),
          },
          date: {
            from: this.$dayjs(license.duration.startsAt)
              .format('DD.MM.YYYY'),
            to: this.$dayjs(license.duration.startsAt)
              .add(license.duration.days, 'day')
              .format('DD.MM.YYYY'),
          },
          type: license.type,
        }));

        [this.indexLicense] = await licenseApp.services.licenseApp.index();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.licenseTable.loading = false;
      }
    },
    confirmDeactivateLicences(deactivateLicences) {
      try {
        // получаем dto и показываем подтверждающий диалог
        // eslint-disable-next-line
        const dtoList = this.licenses.filter((licence) => {
          return deactivateLicences.find((deactivateLicence) => licence.id === deactivateLicence.product.id);
        });

        if (!dtoList) {
          throw new Error('Лицензии не найдены');
        }

        this.deactivateLicencesDTO = dtoList;
        this.showDeactivateLicencesDialog = true;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
    async deactivateLicencesDialogDeactivate() {
      try {
        this.licenseTable.loading = true;
        this.showDeactivateLicencesDialog = false;
        const licenseApp = new this.$di.ddd.License();

        await licenseApp.services.license.deactivateList(this.deactivateLicencesDTO);
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.licenseTable.loading = false;
      }
    },
    deactivateLicencesDialogCancel() {
      this.showDeactivateLicencesDialog = false;
    },
    createLicense(licenseList) {
      this.$router.push(
        { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE_CREATE, query: { apps: licenseList } },
      );
    },
    licenseTableUpdateItems(items) {
      this.licenseTable.items = items;
    },
  },
};
</script>
