<template>
  <VCard class="pa-3">
    <VContainer
      class="pa-5"
      fluid
    >
      <VRow align="center">
        <VCol cols="auto">
          <h2 class="tt-text-title-2">
            Выдача лицензии
          </h2>
        </VCol>
        <VSpacer />
        <VCol cols="auto">
          <VBtn
            icon
            @click="handleCancel"
          >
            <VIcon small>
              fal fa-times
            </VIcon>
          </VBtn>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <LicenseSelect
            v-model="selected"
            :licenses-app="activeLicensesApp"
            :licenses="licenses"
          />
        </VCol>
      </VRow>
      <VRow>
        <VSpacer />
        <VCol cols="auto">
          <TTBtn
            color="tt-secondary"
            @click="handleCancel"
          >
            Отмена
          </TTBtn>

          <TTBtn
            class="ml-3"
            :disabled="!selected.length"
            @click="handleSubmit"
          >
            Продолжить
          </TTBtn>
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<script>
import LicenseSelect from './ui/LicenseSelect.vue';

export default {
  name: 'IssueLicenseForm',
  components: { LicenseSelect },
  props: {
    licensesApp: {
      type: Array,
      default: () => [],
    },
    licenses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    activeLicensesApp() {
      return this.licensesApp.filter((l) => l.active);
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.selected);
    },
  },
};
</script>
