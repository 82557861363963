<template>
  <TTSelect
    v-model="selected"
    multiple
    :items="bundleList"
    return-object
    :attach="false"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :menu-props="{
      maxHeight : '70vh',
      maxWidth : '800px'
    }"
  >
    <template #prepend-inner>
      <TTChip
        v-if="selectedSize"
        close
        close-icon="fal fa-times"
        @click:close="clearSelect"
      >
        {{ selectedSize }}
      </TTChip>
    </template>
    <template #selection="{ item, index}">
      <span
        v-if="!item.isParent"
        class="tt-text-body-2"
      >
        {{ $te(`products.${item.text}`) ? $t(`products.${item.text}`) : item.text }}
        <span v-if="index<selectedSize">, </span>
      </span>
    </template>
    <template #item="{item, attrs : selectListAttrs}">
      <VListItem
        v-bind="selectListAttrs"
        :class="{'ml-3' : !item.isParent}"
        @click="toggleSelect(item)"
      >
        <div
          class="d-flex align-center license-list__item"
        >
          <TTCheckbox
            :value="selectListAttrs.inputValue"
            :label="$te(`products.${item.text}`) ? $t(`products.${item.text}`) : item.text"
            :on-icon="checkAllChildIsSelected(item)"
          />
          <VSpacer />
          <div
            v-if="item.issued && item.issued.length!==0"
            class="d-flex align-center tooltip_wrapper"
          >
            <TTTooltip
              bottom
              right
              open-on-hover
            >
              <template #activator="{ on, attrs}">
                <div
                  class="tt-text-caption tt-light-yellow--text"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $tc('pluralize.licence_select', item.issued.length, { value : item.issued.length }) }}
                  <VIcon
                    small
                    color="tt-light-yellow"
                    class="ml-1"
                  >
                    fal fa-question-circle
                  </VIcon>
                </div>
              </template>
              <div class="flex-column">
                <div
                  v-for="(licence, i) in item.issued"
                  :key="i"
                  class="d-flex"
                >
                  <VSpacer />{{ $t(`licenses.type.${licence.type}`) }} • С {{ licence.from }} до {{ licence.to }}
                </div>
              </div>
            </TTTooltip>
          </div>
        </div>
      </VListItem>
    </template>
  </TTSelect>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'LicenseSelect',
  props: {
    licensesApp: {
      type: Array,
      default: () => [],
    },
    licenses: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Продукт',
    },
    placeholder: {
      type: String,
      default: 'Выберите продукт',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    bundleList() {
      const list = {};
      // получаем массив с уникальными лицензиями
      _.uniqWith(this.licensesApp, _.isEqual)
        .forEach((l) => {
          const { name } = l;
          const bundleName = `${l.bundleName}_bundle` || 'empty_bundle';
          if (!list[bundleName]) {
            list[bundleName] = [{
              text: bundleName,
              bundleName,
              // value необходим когда имя лицензии и бандл совпадают, тогда родителя не будет в списке
              value: Symbol(bundleName),
              isParent: true,
            }];
          }
          list[bundleName].push(
            {
              text: name, bundleName, isParent: false, issued: this.licensesIssued(name),
            },
          );
        });
      return Object.values(list).flat();
    },
    selectedSize() {
      return this.selected.filter((l) => l.isParent === false).length;
    },
  },
  methods: {
    licensesIssued(appName) {
      return this.licenses.filter((l) => l.appName === appName).map((l) => ({
        type: l.licensee.companyId ? 'company' : 'account',
        from: this.$dayjs(l.duration.startsAt)
          .format('DD.MM.YYYY'),
        to: this.$dayjs(l.duration.startsAt)
          .add(l.duration.days, 'day')
          .format('DD.MM.YYYY'),
      }));
    },
    toggleSelect(e) {
      // TODO: декомпозировать функцию, сложно читать
      const set = new Set(this.selected);
      const isSelected = set.has(e);
      if (e.isParent) {
        if (isSelected) {
          this.selected.filter((l) => l.bundleName === e.bundleName).forEach((l) => {
            set.delete(l);
          });
        } else {
          this.bundleList.filter((l) => l.bundleName === e.bundleName).forEach((l) => set.add(l));
        }
      } else if (isSelected) {
        set.delete(e);
        const other = Array.from(set).filter((l) => l.bundleName === e.bundleName);
        if (other.length === 1) {
          set.delete(other[0]);
        }
      } else {
        set.add(this.bundleList.find((l) => l.text === e.bundleName));
        set.add(e);
      }
      this.selected = Array.from(set);
      this.$emit('input', this.selected.filter((l) => !l.isParent).map((l) => l.text));
    },
    checkAllChildIsSelected(item) {
      if (item.isParent) {
        const selectedLength = this.selected.filter((l) => l.bundleName === item.bundleName).length;
        if (selectedLength) {
          const bundleListLength = this.bundleList.filter((l) => l.bundleName === item.bundleName).length;
          if (selectedLength !== bundleListLength) {
            return 'fas fa-minus-square';
          }
        }
      }
      return undefined;
    },
    clearSelect() {
      this.selected = [];
      this.$emit('input', this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.license-list__item{
  width: 100%;
}
.tooltip_wrapper{
  /* чтобы в листе тултип был выше и перехватывал наведение */
  z-index: 1;
}
::v-deep{
  .v-input__prepend-inner{
    margin-top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .v-chip{
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 5px;
  }
  .v-select__selections{
    display: block;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    input {
      top:0;
      left: 0;
    }
  }
  .v-input--checkbox{
    margin: 0;
    padding: 0;
  }
}
</style>
